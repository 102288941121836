import React from 'react';
import PropTypes from 'prop-types';
import { SmallText } from '../shared/styled/region';

const CardPolaroid = ({ text, src, alt = '' }) => {
  return (
    <li className="card">
      <figure
        style={{
          margin: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flexGrow: 1,
        }}
      >
        <img
          style={{ maxWidth: '100%', marginBottom: '1rem' }}
          src={src}
          alt={alt}
        />
        <figcaption style={{ textAlign: 'center' }}>
          <SmallText>{text}</SmallText>
        </figcaption>
      </figure>
    </li>
  );
};

CardPolaroid.propTypes = {
  text: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

export default CardPolaroid;
