import React, { useState } from 'react';
import Iframe from 'react-responsive-embed';
import Spinner from 'react-spinkit';

const IframeWithLoading = props => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <div style={{ position: 'relative' }}>
      {isLoading && (
        <Spinner
          name="wandering-cubes"
          color="#7BC77B"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            zIndex: 10,
          }}
        />
      )}
      <Iframe {...props} onLoad={() => setIsLoading(false)} />
    </div>
  );
};

export default IframeWithLoading;
