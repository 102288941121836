import React from 'react';
import IframeWithLoading from '../components/IframeWithLoading';
import { FaAndroid, FaApple, FaWalking, FaCarSide } from 'react-icons/fa';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import { PageTitle } from '../components/shared/styled/headings';
import { ExternalLink } from '../components/shared/styled/a';
import { SmallText, Cards, H2, H3 } from '../components/shared/styled/region';
import { Content, Hr } from '../components/shared/styled/layout';
import app1 from '../images/apps/app1.png';
import app2 from '../images/apps/app2.png';
import chateauLagarde from '../images/fromWeb/chateau-lagarde.jpg';
import chateauMiglos from '../images/fromWeb/chateau-miglos.jpg';
import grotteLombrive from '../images/fromWeb/grotte-lombrive.jpg';
import grotteNiaux from '../images/fromWeb/grotte-niaux.jpg';
import rivereLabouiche from '../images/fromWeb/riviere-souterraine-labouiche.jpg';
import plateauDeBeille from '../images/fromWeb/plateau-de-beille.jpg';
import bonascre from '../images/fromWeb/bonascre.jpg';
import montsDOlme from '../images/pleinAir/monts_d_olmes.jpg';
import fontaineFontestorbes from '../images/fromWeb/fontaine-fontestorbes.jpg';
import picSaintBarthelemy from '../images/fromWeb/picSaintBarthelemy.jpg';
import cheminPicSaintBarthelemy from '../images/pleinAir/chemin_pic_st_barthelemy.jpg';
import moulzoune1 from '../images/pleinAir/moulzoune-montferrier-01.jpg';
import moulzoune2 from '../images/pleinAir/moulzoune-montferrier-02.jpg';
import roquefortLesCascades from '../images/pleinAir/roquefort-les-cascades.jpg';
import montFourcat from '../images/fromWeb/mont-fourcat.jpg';
import etangAppy from '../images/fromWeb/etang-appy.jpg';
import collioure from '../images/fromWeb/collioure.jpg';
import CardPolaroid from '../components/CardPolaroid';

const hikingCards = [
  {
    text: 'Pic Saint-Barthélémy',
    src: picSaintBarthelemy,
    alt: 'Photo du Pic Saint-Barthélémy',
  },
  {
    text: 'Sur le chemin du Pic Saint-Barthélémy',
    src: cheminPicSaintBarthelemy,
    alt: 'Photo prise sur le Sur le chemin du Pic Saint-Barthélémy',
  },
  {
    text: 'Mont Fourcat',
    src: montFourcat,
    alt: 'Photo du Mont Fourcat',
  },
  {
    text: "Étang d'Appy",
    src: etangAppy,
    alt: "Photo de l'Étang d'Appy",
  },
  {
    text: 'Étang de Moulzoune',
    src: moulzoune1,
    alt: "Photo de l'Étang de Moulzoune",
  },
  {
    text: 'Étang de Moulzoune',
    src: moulzoune2,
    alt: "Photo de l'Étang de Moulzoune",
  },
  {
    text: 'Roquefort-les-cascades',
    src: roquefortLesCascades,
    alt: 'Photo de Roquefort-les-cascades',
  },
  {
    text: 'Fontaine intermittente de Fontestorbes',
    src: fontaineFontestorbes,
    alt: 'Photo de la Fontaine intermittente de Fontestorbes',
  },
];

const PageNotreRegion = () => {
  return (
    <Layout>
      <Helmet>
        <title>Notre région - Gîtes en Pyrénées Cathares</title>
        <meta
          name="description"
          content={`Tourisme, randonnées ou visites, découvrez ici quelques idées de sorties.`}
        />
      </Helmet>
      <Content style={{ paddingTop: '3rem' }}>
        <PageTitle>Notre région</PageTitle>

        <section>
          <H2>Chateaux, forteresses et sites cathares</H2>

          <Cards>
            <li className="card">
              <div>
                <H3>Château de Montségur</H3>
                <SmallText>09300 Montségur</SmallText>
                <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                  <FaWalking title="à pied" style={{ marginRight: '0.3rem' }} />{' '}
                  1h20{' '}
                  <FaCarSide
                    title="en voiture"
                    style={{ marginLeft: '1rem', marginRight: '0.3rem' }}
                  />{' '}
                  6mn
                </SmallText>
                <SmallText>
                  <ExternalLink
                    href="http://www.montsegur.fr"
                    text="http://www.montsegur.fr"
                  />
                </SmallText>
              </div>
              <IframeWithLoading
                width="560"
                height="315"
                src="https://www.youtube-nocookie.com/embed/VtrGWmJ50mk"
                allowFullScreen
                className="iframe"
                allow="encrypted-media"
              />
            </li>
            <li className="card">
              <div>
                <H3>Château de Roquefixade</H3>
                <SmallText>09300 Roquefixade</SmallText>
                <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                  <FaCarSide
                    title="en voiture"
                    style={{ marginRight: '0.3rem' }}
                  />{' '}
                  16mn
                </SmallText>
                <SmallText>
                  <ExternalLink
                    href="https://www.roquefixade.fr/chateau-de-roquefixade.php?data_id=7&dbox_id=23&page=1"
                    text="https://www.roquefixade.fr"
                  />
                </SmallText>
              </div>
              <IframeWithLoading
                width="560"
                height="315"
                src="https://www.youtube-nocookie.com/embed/qbgaJhIO2ow"
                allowFullScreen
                className="iframe"
                allow="encrypted-media"
              />
            </li>
            <li className="card">
              <div>
                <H3>Château de Foix</H3>
                <SmallText>09000 Foix</SmallText>
                <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                  <FaCarSide
                    title="en voiture"
                    style={{ marginRight: '0.3rem' }}
                  />{' '}
                  25mn
                </SmallText>
                <SmallText>
                  <ExternalLink
                    href="http://www.sites-touristiques-ariege.fr/sites-touristiques-ariege/chateau-de-foix"
                    text="http://www.sites-touristiques-ariege.fr"
                  />
                </SmallText>
              </div>
              <IframeWithLoading
                width="560"
                height="315"
                src="https://www.youtube-nocookie.com/embed/dWonimpSYtU"
                allowFullScreen
                className="iframe"
                allow="encrypted-media"
              />
            </li>
            <li className="card">
              <div>
                <H3>Château de Puivert</H3>
                <SmallText>11230 Puivert</SmallText>
                <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                  <FaCarSide
                    title="en voiture"
                    style={{ marginRight: '0.3rem' }}
                  />{' '}
                  30mn
                </SmallText>
                <SmallText>
                  <ExternalLink
                    href="http://www.chateau-de-puivert.com/"
                    text="http://www.chateau-de-puivert.com/"
                  />
                </SmallText>
              </div>
              <IframeWithLoading
                width="560"
                height="315"
                src="https://www.youtube-nocookie.com/embed/x90QhstKpUU"
                allowFullScreen
                className="iframe"
                allow="encrypted-media"
              />
            </li>
            <li className="card">
              <div>
                <H3>Château de Lagarde</H3>
                <SmallText>09500 Lagarde</SmallText>
                <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                  <FaCarSide
                    title="en voiture"
                    style={{ marginRight: '0.3rem' }}
                  />{' '}
                  30mn
                </SmallText>
                <SmallText>
                  <ExternalLink
                    href="https://chateaudelagarde7.wixsite.com/chateaudelagarde"
                    text="https://chateaudelagarde7.wixsite.com"
                  />
                </SmallText>
              </div>
              <img
                src={chateauLagarde}
                alt="Château de Lagarde"
                style={{ maxWidth: '100%' }}
              />
            </li>
            <li className="card">
              <div>
                <H3>Château de Miglos</H3>
                <SmallText>09400 Miglos</SmallText>
                <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                  <FaCarSide
                    title="en voiture"
                    style={{ marginRight: '0.3rem' }}
                  />{' '}
                  35mn
                </SmallText>
                <SmallText>
                  <ExternalLink
                    href="https://chateau-miglos.fr"
                    text="https://chateau-miglos.fr"
                  />
                </SmallText>
              </div>
              <img
                src={chateauMiglos}
                alt="Château de Miglos"
                style={{ maxWidth: '100%' }}
              />
            </li>
            <li className="card">
              <div>
                <H3>Château Chalabre</H3>
                <SmallText>11230 Chalabre</SmallText>
                <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                  <FaCarSide
                    title="en voiture"
                    style={{ marginRight: '0.3rem' }}
                  />{' '}
                  40mn
                </SmallText>
                <SmallText>
                  <ExternalLink
                    href="http://www.chateau-chalabre.com"
                    text="http://www.chateau-chalabre.com"
                  />
                </SmallText>
              </div>
              <IframeWithLoading
                width="560"
                height="315"
                src="https://www.youtube-nocookie.com/embed/PXmvMVGrfq4"
                allowFullScreen
                className="iframe"
                allow="encrypted-media"
              />
            </li>
            <li className="card">
              <div>
                <H3>Château d'Usson</H3>
                <SmallText>Hameau d'Usson, 09460 Rouze</SmallText>
                <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                  <FaCarSide
                    title="en voiture"
                    style={{ marginRight: '0.3rem' }}
                  />{' '}
                  1h
                </SmallText>
                <SmallText>
                  <ExternalLink
                    href="https://www.payscathare.org/les-sites/chateau-d-usson"
                    text="https://www.payscathare.org"
                  />
                </SmallText>
              </div>
              <IframeWithLoading
                width="560"
                height="315"
                src="https://www.youtube-nocookie.com/embed/Eo8FruHM3U0"
                allowFullScreen
                className="iframe"
                allow="encrypted-media"
              />
            </li>
            <li className="card">
              <div>
                <H3>Château d'Arques</H3>
                <SmallText>Château d'Arques, D613, 11190 Arques</SmallText>
                <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                  <FaCarSide
                    title="en voiture"
                    style={{ marginRight: '0.3rem' }}
                  />{' '}
                  1h10
                </SmallText>
                <SmallText>
                  <ExternalLink
                    href="https://www.payscathare.org/les-sites/chateau-d-arques"
                    text="https://www.payscathare.org"
                  />
                </SmallText>
              </div>
              <IframeWithLoading
                width="560"
                height="315"
                src="https://www.youtube-nocookie.com/embed/yPvEBhvebUY"
                allowFullScreen
                className="iframe"
                allow="encrypted-media"
              />
            </li>
            <li className="card">
              <div>
                <H3>Château de Puilaurens</H3>
                <SmallText>11140 Puilaurens</SmallText>
                <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                  <FaCarSide
                    title="en voiture"
                    style={{ marginRight: '0.3rem' }}
                  />{' '}
                  1h10
                </SmallText>
                <SmallText>
                  <ExternalLink
                    href="https://www.chateau-puilaurens.com"
                    text="https://www.chateau-puilaurens.com"
                  />
                </SmallText>
              </div>
              <IframeWithLoading
                width="560"
                height="315"
                src="https://www.youtube-nocookie.com/embed/4XfwCM7nnWU"
                allowFullScreen
                className="iframe"
                allow="encrypted-media"
              />
            </li>
          </Cards>
        </section>

        <Hr />

        <section>
          <H2>Applications mobiles du Pays Cathare</H2>

          <p>
            Pour plonger dans l{"'"}histoire téléchargez les applications {'"'}
            Pays Cathare, le guide{'"'} et {'"'}Castrum, le jeu{'"'},
            disponibles sur Android et iOS.
          </p>
          <IframeWithLoading
            src="https://www.youtube-nocookie.com/embed/hejBUpyu3RY"
            ratio="16:9"
            allowFullScreen
            className="iframe-app"
            allow="encrypted-media"
          />

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: '1rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <img
                src={app1}
                alt="Icône de l'application Pays
          Cathare"
                style={{ width: '80px' }}
              />
              <div>
                <SmallText>
                  <ExternalLink
                    href="https://play.google.com/store/apps/details?id=io.departementaude.payscathare"
                    text="Pays Cathare - Android"
                    icon={<FaAndroid />}
                    button
                  />
                </SmallText>
                <SmallText>
                  <ExternalLink
                    href="https://apps.apple.com/fr/app/cathar-country/id1465246971"
                    text="Pays Cathare - iOS"
                    icon={<FaApple />}
                    button
                  />
                </SmallText>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <img
                src={app2}
                alt="Icône de l'application Castrum"
                style={{ width: '80px' }}
              />
              <div>
                <SmallText>
                  <ExternalLink
                    href="https://play.google.com/store/apps/details?id=io.departementaude.castrum"
                    text="Castrum - Android"
                    icon={<FaAndroid />}
                    button
                  />
                </SmallText>
                <SmallText>
                  <ExternalLink
                    href="https://itunes.apple.com/fr/app/castrum/id1465245349"
                    text="Castrum - iOS"
                    icon={<FaApple />}
                    button
                  />
                </SmallText>
              </div>
            </div>
          </div>
        </section>

        <Hr />

        <section>
          <H2>Villes et cités</H2>
          <Cards>
            <li className="card">
              <div>
                <H3>Cité médiévale de Mirepoix</H3>
                <SmallText>09500 Mirepoix</SmallText>
                <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                  <FaCarSide
                    title="en voiture"
                    style={{ marginRight: '0.3rem' }}
                  />{' '}
                  30mn
                </SmallText>
                <SmallText>
                  <ExternalLink
                    href="https://www.tourisme-mirepoix.com"
                    text="https://www.tourisme-mirepoix.com"
                  />
                </SmallText>
              </div>
              <IframeWithLoading
                width="560"
                height="315"
                src="https://www.youtube-nocookie.com/embed/ChlgQaHTh-o"
                allowFullScreen
                className="iframe"
                allow="encrypted-media"
              />
            </li>
            <li className="card">
              <div>
                <H3>Cité médiévale de Carcassonne</H3>
                <SmallText>11000 Carcassonne</SmallText>
                <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                  <FaCarSide
                    title="en voiture"
                    style={{ marginRight: '0.3rem' }}
                  />{' '}
                  1h15
                </SmallText>
                <SmallText>
                  <ExternalLink
                    href="http://www.remparts-carcassonne.fr"
                    text="http://www.remparts-carcassonne.fr"
                  />
                </SmallText>
              </div>
              <IframeWithLoading
                width="560"
                height="315"
                src="https://www.youtube-nocookie.com/embed/xbCTdPg1ZvQ"
                allowFullScreen
                className="iframe"
                allow="encrypted-media"
              />
            </li>
            <li className="card">
              <div>
                <H3>
                  Collioure, la {'"'}la perle de la côte vermeille{'"'}
                </H3>
                <SmallText>66190 Collioure</SmallText>
                <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                  <FaCarSide
                    title="en voiture"
                    style={{ marginRight: '0.3rem' }}
                  />{' '}
                  2h30
                </SmallText>
                <SmallText>
                  <ExternalLink
                    href="https://www.collioure.com"
                    text="https://www.collioure.com"
                  />
                </SmallText>
              </div>
              <img
                src={collioure}
                alt="Eglise Notre-Dame-des-Anges de Collioure"
                style={{ maxWidth: '100%' }}
              />
            </li>
          </Cards>
        </section>

        <Hr />

        <section>
          <H2>Grottes et rivières souterraines</H2>

          <Cards>
            <li className="card">
              <H3>Grotte de Lombrives</H3>
              <SmallText>09400 Ornolac-Ussat-les-Bains</SmallText>
              <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                <FaCarSide
                  title="en voiture"
                  style={{ marginRight: '0.3rem' }}
                />{' '}
                30mn
              </SmallText>
              <SmallText>
                <ExternalLink
                  href="https://www.grottedelombrives.com"
                  text="https://www.grottedelombrives.com"
                />
              </SmallText>
              <img
                src={grotteLombrive}
                alt="Grotte de Lombrive"
                style={{ maxWidth: '100%' }}
              />
            </li>
            <li className="card">
              <H3>Grotte de Niaux</H3>
              <SmallText>Grotte de Niaux, 09400 Niaux</SmallText>
              <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                <FaCarSide
                  title="en voiture"
                  style={{ marginRight: '0.3rem' }}
                />{' '}
                35mn
              </SmallText>
              <SmallText>
                <ExternalLink
                  href="http://www.sites-touristiques-ariege.fr/sites-touristiques-ariege/grotte-de-niaux"
                  text="http://www.sites-touristiques-ariege.fr"
                />
              </SmallText>
              <img
                src={grotteNiaux}
                alt="Grotte de Niaux"
                style={{ maxWidth: '100%' }}
              />
            </li>
            <li className="card">
              <H3>Rivière souterraine de Labouiche</H3>
              <SmallText>09000 Baulou</SmallText>
              <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                <FaCarSide
                  title="en voiture"
                  style={{ marginRight: '0.3rem' }}
                />{' '}
                35mn
              </SmallText>
              <SmallText>
                <ExternalLink
                  href="https://www.labouiche.com"
                  text="https://www.labouiche.com"
                />
              </SmallText>
              <img
                src={rivereLabouiche}
                alt="Rivière souterraine de Labouiche"
                style={{ maxWidth: '100%' }}
              />
            </li>
          </Cards>
        </section>

        <Hr />

        <section>
          <H2>Loisirs de plein air</H2>

          <H3>Randonnées</H3>
          <p>
            Les gîtes se trouvent à proximité des{' '}
            <ExternalLink
              style={{ display: 'inline' }}
              href="https://fr.wikipedia.org/wiki/Sentier_cathare"
              text="sentiers cathares (GR367)"
            />
            , du{' '}
            <ExternalLink
              style={{ display: 'inline' }}
              href="https://www.france-randos.com/randonnee/lavelanet/ariege-09/le-chemin-des-tisserand"
              text="chemin des Tisserands"
            />{' '}
            et du{' '}
            <ExternalLink
              style={{ display: 'inline' }}
              href="https://mapio.net/s/38991157/"
              text="GR7b"
            />
            .
          </p>
          <p>
            Depuis la commune de Montferrier des ballades vous emmèneront au{' '}
            <ExternalLink
              style={{ display: 'inline' }}
              href="https://fr.wikipedia.org/wiki/Pic_de_Saint-Barth%C3%A9lemy"
              text="Pic St Barthélémy"
            />
            , au{' '}
            <ExternalLink
              style={{ display: 'inline' }}
              href="https://fr.wikipedia.org/wiki/Mont_Fourcat"
              text="Mont Fourcat"
            />
            , à{' '}
            <ExternalLink
              style={{ display: 'inline' }}
              href="https://fr.wikipedia.org/wiki/Étang_d%27Appy"
              text="l'Étang d'Appy"
            />
            , à{' '}
            <ExternalLink
              style={{ display: 'inline' }}
              href="http://www.ariege-pyrenees-cathares.com/montferrier/etang-de-moulzoune-et-ancienne-mine-de-talc/tabid/3476/offreid/cad6a4f5-c108-4eae-b37b-e5c9cc27c2f5"
              text="l'Étang de Moulzoune"
            />
            , à{' '}
            <ExternalLink
              style={{ display: 'inline' }}
              href="http://www.ariegepyrenees.com/roquefort-les-cascades/les-cascades-a-roquefort-les-cascades/tabid/1019/offreid/faa04553-fe92-4bb3-b027-c2d9ba90ca57"
              text="Roquefort-les-cascades."
            />
          </p>
          <Cards>
            {hikingCards.map(poi => (
              <CardPolaroid
                key={poi.text}
                text={poi.text}
                src={poi.src}
                alt={poi.alt}
              />
            ))}
          </Cards>

          <p>
            De nombreux chemins vous feront découvrir une nature généreuse
            chargée d{"'"}
            histoire. Randonnées à la belle saison et ballades en raquettes en
            hiver.
          </p>
          <p>
            <ExternalLink
              href="https://www.visorando.com/randonnee-montferrier.html"
              text="Randonnées à proximité de Montferrier sur visorando.com"
            />
          </p>

          <H3>Stations de ski</H3>
          <Cards>
            <li className="card">
              <H3>Les Monts d{"'"}Olmes</H3>
              <SmallText>Petite station familiale</SmallText>
              <SmallText>09300 Montferrier</SmallText>
              <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                <FaCarSide
                  title="en voiture"
                  style={{ marginRight: '0.3rem' }}
                />{' '}
                20mn
              </SmallText>
              <SmallText>
                <ExternalLink
                  href="https://www.montsdolmes.ski/fr/"
                  text="https://www.montsdolmes.ski"
                />
              </SmallText>
              <img
                src={montsDOlme}
                alt="Photo de la station des Monts d'Olme"
                style={{ maxWidth: '100%' }}
              />
            </li>
            <li className="card">
              <H3>Bonascre</H3>
              <SmallText>Station d'Ax 3 Domaines</SmallText>
              <SmallText>09110 Ax-les-Thermes</SmallText>
              <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                <FaCarSide
                  title="en voiture"
                  style={{ marginRight: '0.3rem' }}
                />{' '}
                1h
              </SmallText>
              <SmallText>
                <ExternalLink
                  href="https://www.ax.ski/fr/"
                  text="https://www.ax.ski"
                />
              </SmallText>
              <img
                src={bonascre}
                alt="Photo de Bonascre"
                style={{ maxWidth: '100%' }}
              />
            </li>
            <li className="card">
              <H3>Plateau de Beille</H3>
              <SmallText>Beille, 09310 Les Cabannes</SmallText>
              <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                <FaCarSide
                  title="en voiture"
                  style={{ marginRight: '0.3rem' }}
                />{' '}
                1h20
              </SmallText>
              <SmallText>
                <ExternalLink
                  href="https://www.beille.fr/"
                  text="https://www.beille.fr"
                />
              </SmallText>
              <img
                src={plateauDeBeille}
                alt="Photo du Plateau de Beille enneigé"
                style={{ maxWidth: '100%' }}
              />
            </li>
          </Cards>
        </section>

        <Hr />

        <section>
          <H2>Musées</H2>
          <Cards withoutImage>
            <li className="card">
              <H3>Musée du Textile et du Peigne en Corne</H3>
              <SmallText>65 Rue Jean Jaurès, 09300 Lavelanet</SmallText>
              <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                <FaCarSide
                  title="en voiture"
                  style={{ marginRight: '0.3rem' }}
                />{' '}
                14mn
              </SmallText>
              <SmallText>
                <ExternalLink
                  href="http://www.museedutextileariege.fr/"
                  text="http://www.museedutextileariege.fr"
                />
              </SmallText>
            </li>
            <li className="card">
              <H3>Les forges de Pyrène</H3>
              <SmallText>Avenue de Paris, 09330, Foix</SmallText>
              <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                <FaCarSide
                  title="en voiture"
                  style={{ marginRight: '0.3rem' }}
                />{' '}
                25mn
              </SmallText>
              <SmallText>
                <ExternalLink
                  noMarginBottom
                  href="http://www.forges-de-pyrene.com"
                  text="http://www.forges-de-pyrene.com"
                />
              </SmallText>
            </li>
            <li className="card">
              <H3>Parc de la Préhistoire</H3>
              <SmallText>Route de Banat, 09400 Tarascon-sur-Ariège</SmallText>
              <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                <FaCarSide
                  title="en voiture"
                  style={{ marginRight: '0.3rem' }}
                />{' '}
                30mn
              </SmallText>
              <SmallText>
                <ExternalLink
                  noMarginBottom
                  href="http://www.sites-touristiques-ariege.fr/sites-touristiques-ariege/parc-de-la-prehistoire"
                  text="http://www.sites-touristiques-ariege.fr"
                />
              </SmallText>
            </li>
            <li className="card">
              <H3>Le Jardin Extraordinaire</H3>
              <SmallText>Gouziaud, 09300 Lieurac</SmallText>
              <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                <FaCarSide
                  title="en voiture"
                  style={{ marginRight: '0.3rem' }}
                />{' '}
                30mn
              </SmallText>
              <SmallText>
                <ExternalLink
                  noMarginBottom
                  href="https://www.lejardinextraordinaire.net"
                  text="https://www.lejardinextraordinaire.net"
                />
              </SmallText>
            </li>
            <li className="card">
              <H3>Musée des Dinosaures</H3>
              <SmallText>
                1 Place Maréchal de Lattre de Tassigny, 11260 Esperaza
              </SmallText>
              <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                <FaCarSide
                  title="en voiture"
                  style={{ marginRight: '0.3rem' }}
                />{' '}
                1h
              </SmallText>
              <SmallText>
                <ExternalLink
                  noMarginBottom
                  href="http://www.dinosauria.org/fr/"
                  text="http://www.dinosauria.org/fr"
                />
              </SmallText>
            </li>
          </Cards>
        </section>
      </Content>
    </Layout>
  );
};

export default PageNotreRegion;
